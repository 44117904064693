import React from 'react'

export default () => (
    <style jsx="true">{`
.cards-stories-heading {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #e6e6e6;
}
.single-storie {
    display: flex;
    margin-bottom: 18px;
}
.stories-img {
    background-image: url(/images/default-placeholder-1024x1024.webp);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}
.storie-detail {
    padding-left: 8px;
}
.single-storie .services-small-heading {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    margin: 0 0 10px;
}
.single-storie p {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 8px;
}
    `}</style>
)