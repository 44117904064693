import React from "react";
import Style from "./style"

function FeaturedStories() {
    return(
        <div className="col-sm-4">
            <h2 className="services-small-heading cards-stories-heading">
                FEATURED STORIES
            </h2>

            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>
            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>
            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>


            <h2 className="services-small-heading cards-stories-heading">
                RECENT POSTS
            </h2>

            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>
            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>
            <div className="single-storie">
                <div className="stories-img"></div>
                <div className="storie-detail">
                    <h2 className="services-small-heading">
                        Lorem Ipsum.</h2>
                    <p>Our spines were made to move, and in turn so were
                        the rest of our bodies. Exercise,</p>
                    <p>February 25, 2014</p>
                </div>
            </div>
            <Style/>
        </div>

    )
}
export default FeaturedStories