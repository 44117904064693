import React from "react";
import Layout from "../components/layout";
import BlogsDetail from "../components/blogdetailcomponent";


function BlogDetail() {
    return (
        <Layout headimg={"/images/top-logo-2.webp"} headclr={"black-list"} headbg={"whitee-bg"} callicon ={"/images/call-answer (1).webp"}>
            <BlogsDetail/>

        </Layout>
    )
}
export default BlogDetail;