import React from 'react'

export default () => (
    <style jsx="true">{`
.cards-with-shadow {
    background-color: #fff;
    padding: 15px 10px;
    box-shadow: 1px 1px 28px rgba(51, 51, 51, 0.17);
    margin-bottom: 30px;
}
.cards-stories-heading {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 15px;
    margin: 0 0 15px;
    border-bottom: 1px solid #e6e6e6;
}
.cards-with-shadow .img-background {
    background-image: url(/images/default-placeholder-1024x1024.webp);
    background-size: 100% 100%;
    min-height: 350px;
    width: 100%;
    background-repeat: no-repeat;
}
.cards-with-shadow span {
    font-size: 12px;
    font-weight: 500;
}
.cards-with-shadow p {
    font-size: 14px;
    margin: 0 0 15px;
}
.for-card-user-flex {
    display: flex;
}
.j-img-div {
    background-image: url(/images/sngl-user-img.webp);
    background-position: center;
    background-repeat: no-repeat;
    height: 83px;
    width: 100%;
    background-size: 100% 100%;
    border-radius: 64px;
    border: 2px solid #dcdedf;
}
.j-details-detail-page {
    font-size: 12px!important;
    font-weight: 500;
    color: #8b8b8b;
    margin: 0 0 12px;
}
    `}</style>
)