import React from "react";
import BlogDetailCard from "../detailcardcomponent";
import Story from "../featuredstories"

function BlogdetailComponent() {
    return(
        <div className="blog-bg">
        <div className="container blog-main-col ">
        <div className="col-md-12 d-flex">
            <BlogDetailCard/>
            <Story/>
        </div></div></div>
    )
}
export default BlogdetailComponent;